import { useRecoilState } from 'recoil'
import {
  activeTeamState,
  selectedGameState,
  selectedPaymentState,
} from '../../common/atoms/atoms'
import {
  activeRequestState,
  deviceDataState,
  inGameAccountFromState,
  isOpenInGameAccountModalState,
  isOpenStripePaymentState,
  isPlacingOrderState,
  paymentFromState,
  promoState,
  quantityState,
  selectedChampionsState,
  selectedProductAddOnsState,
  selectedProductState,
  stripeRadarSessionState,
} from '../../common/atoms/checkout'
import { RequestResponse } from '../../types/requests/responses/request-response'
import { updateStoredControl } from '../cache/user-control-storage'
import { UserLocalstorage } from '../../types/users/user-local-storage'
import { SessionStatusEnum } from '../../common/enums/session-status.enum'
import { CheckoutRequest } from '../../types/checkout/requests/checkout-request'
import { usePostCheckout } from './usePostCheckout'
import { useCreateTotalAmount } from './useCreateTotalAmount'
import { useUser } from '../users/useUser'
import { useSession } from 'next-auth/react'
import { useEffect } from 'react'
import { PaymentGatewayEnum } from '../../common/enums/payment-gateway.enum'
import { toast } from 'react-toastify'
import {
  ErrorResponse,
  readableError,
} from '../../types/errors/responses/error-response'
import { useLogGAPurchaseEvent } from './useLogGAPurchaseEvent'
import { useCreditAmount } from '../currencies/useCurrencies'
import { useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { inSessionUrl } from '../../common/urls/homeUrl'
import { fetchFingerprint } from '../../lib/fingerprint'
import { isNil } from '../../common/utils/common.utils'
import { useInGameAccounts } from '../accounts/useInGameAccounts'

export function useCheckout() {
  const { user } = useUser()
  const { status } = useSession()
  const [selectedPayment] = useRecoilState(selectedPaymentState)
  const [promoCode] = useRecoilState(promoState)
  const [quantity] = useRecoilState(quantityState)
  const [game] = useRecoilState(selectedGameState)
  const [, setIsOpenStripeAddPayment] = useRecoilState(isOpenStripePaymentState)

  const [activeTeam] = useRecoilState(activeTeamState)
  const [activeRequest, setActiveRequest] = useRecoilState(activeRequestState)
  const [, setPaymentFromState] = useRecoilState(paymentFromState)
  const { logGAPurchaseEvent } = useLogGAPurchaseEvent()
  const [deviceData] = useRecoilState(deviceDataState)
  const [, setIsPlacingOrder] = useRecoilState(isPlacingOrderState)

  const [radarSessionId] = useRecoilState(stripeRadarSessionState)
  const { yourCredit } = useCreditAmount()
  const router = useRouter()
  const [selectedProduct] = useRecoilState(selectedProductState)
  const [selectedAddons] = useRecoilState(selectedProductAddOnsState)
  const { hasDefaultInGameAccount } = useInGameAccounts(true)
  const [, setInGameAccountModalOpen] = useRecoilState(
    isOpenInGameAccountModalState,
  )
  const [, setInGameAccountFrom] = useRecoilState(inGameAccountFromState)
  const [champions] = useRecoilState(selectedChampionsState)

  const {
    mutate,
    data: checkoutResponse,
    isLoading,
    isSuccess,
    isError,
    error: checkoutError,
  } = usePostCheckout()

  const { data: total, isLoading: isTotalLoading } = useCreateTotalAmount()
  const queryClient = useQueryClient()
  // Handle checkout response.
  useEffect(() => {
    if (checkoutResponse) {
      clearTotalAmountRelatedCache()
    }

    if (checkoutResponse?.request) {
      logGAPurchaseEvent(
        checkoutResponse,
        checkoutResponse?.transaction?.unitAmount ?? 0,
        user?.email,
        checkoutResponse?.isNewUser ?? false,
        user?.id,
      )
      switchToSearchingMode(checkoutResponse.request)
    }
  }, [checkoutResponse])

  // Handle checkout error.
  useEffect(() => {
    if (checkoutError)
      toast.error(readableError(checkoutError as ErrorResponse), {
        autoClose: false,
      })
  }, [checkoutError])

  const clearTotalAmountRelatedCache = () => {
    try {
      queryClient.removeQueries({
        predicate: (query) => {
          return query.queryKey[0] === 'total-amount'
        },
      })
    } catch (e) {}
  }

  async function createRequest(
    gateway = PaymentGatewayEnum.STRIPE_PAYMENT_INTENT,
    isRematch = false,
  ): Promise<CheckoutRequest | undefined> {
    if (isLoading || isTotalLoading) {
      return
    }

    // Save user last selected service.
    updateStoredControl({
      selectedGame: game,
      selectedProduct:
        activeRequest?.orderItem?.product ??
        activeTeam?.product ??
        selectedProduct,
    } as UserLocalstorage)

    //Check username not null and server not null
    if (isNil(activeTeam) && !hasDefaultInGameAccount()) {
      setIsPlacingOrder(false)
      setInGameAccountFrom('checkout')
      setInGameAccountModalOpen(true)
      return
    }

    if (status === SessionStatusEnum.AUTHENTICATED && !user) {
      setIsPlacingOrder(false)
      return
    }

    if (
      isNil(selectedProduct) &&
      isNil(activeTeam?.product) &&
      isNil(activeRequest)
    ) {
      setIsPlacingOrder(false)
      return
    }

    let visitorId
    let fingerprintRequestId

    try {
      const fingerprint = await fetchFingerprint()
      visitorId = fingerprint?.visitorId
      fingerprintRequestId = fingerprint?.requestId
    } catch (e) {}

    let request: CheckoutRequest = {
      productId:
        activeRequest?.orderItem?.product?.id ??
        activeTeam?.product.id ??
        selectedProduct?.id ??
        -1,
      gateway: gateway,
      quantity: quantity,
      email: user?.email,
      isRematch: isRematch,
      deviceData,
      visitorId: visitorId,
      fingerprintRequestId: fingerprintRequestId,
      addonIds:
        activeRequest?.transactionSnapshot?.addonIds ??
        activeTeam?.addons?.map((addon) => addon.id) ??
        selectedAddons?.map((addon) => addon.id),
      streamer: selectedProduct?.streamer,
      radarSessionId,
      teamId: activeRequest ? undefined : activeTeam?.id,
      promoCode: promoCode?.code,
      champions:
        selectedProduct?.id === 27 || selectedProduct?.id === 122
          ? champions
          : undefined,
    }

    return request
  }

  async function submit(
    gateway = PaymentGatewayEnum.STRIPE_PAYMENT_INTENT,
    isRematch = false,
  ) {
    const request = await createRequest(gateway, isRematch)

    if (!request) {
      setIsPlacingOrder(false)
      return
    }

    if ((promoCode || (yourCredit ?? 0) > 0) && (total?.total ?? 0) === 0) {
      mutate(request)
      return
    }

    if (selectedPayment === null || selectedPayment === undefined) {
      setIsPlacingOrder(false)
      setPaymentFromState('checkout')
      setIsOpenStripeAddPayment(true)
      return
    }

    mutate(request)
  }

  function switchToSearchingMode(request: RequestResponse) {
    setActiveRequest(request)
    router.replace(inSessionUrl)
  }

  return {
    submit,
    total,
    isLoading,
    isSuccess,
    isError,
  }
}
